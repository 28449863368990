import React, { Fragment, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  VehicleModel,
  buildLatestVehiclesPath,
  VehicleEntities,
  buildMakeModelDefaultYear,
} from 'client/data/models/vehicle-v2';
import { isPreProd, isNew } from 'site-modules/shared/utils/publication-states';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { venomHistory } from 'client/utils/history/venom-history';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { SpeculationRule } from 'site-modules/shared/components/speculation-rule/speculation-rule';

const LABEL_MAP = {
  [true]: 'Year',
  [false]: 'Select year',
};

function YearDropdownUI({ newModelYears, params, defaultYear, value, descriptionId, isCore6334Enabled }) {
  const wrapperRef = useRef();

  const selectYear = useCallback(({ url }) => {
    venomHistory.push(url);
  }, []);

  if (!newModelYears) {
    return null;
  }

  const newYears = newModelYears
    .filter(({ pubStates }) => isPreProd(pubStates) || isNew(pubStates))
    .map(({ modelYearId, pubStates, year }) => ({
      id: `${modelYearId}`,
      label: isPreProd(pubStates) ? `${year} - Upcoming` : `${year} - New`,
      url: getCoreUrl({ makeSlug: params.make, modelSlug: params.model, year, defaultYear }),
    }));
  const speculationUrls = newYears.filter(({ id }) => id !== value).map(({ url }) => url);

  return (
    <Fragment>
      <StyledSelect
        wrapperRef={wrapperRef}
        value={value}
        onChange={selectYear}
        name="select-year"
        valueKey="id"
        labelText={LABEL_MAP[isCore6334Enabled]}
        wrapperClassName="label-inside mr-1 mb-1_5 w-100"
        labelClassName="small mb-0_5"
        overrideClasses="year-select d-block w-100 px-1 pt-1_75 pb-0_5 size-16 bg-white text-black rounded-8"
        options={newYears}
        data-tracking-id="year_select"
        aria-describedby={descriptionId}
      />
      <SpeculationRule urls={speculationUrls} observeRef={wrapperRef} />
    </Fragment>
  );
}

YearDropdownUI.propTypes = {
  params: CorePageParams.isRequired,
  newModelYears: VehicleEntities.LatestVehicleYears,
  defaultYear: PropTypes.number,
  value: PropTypes.string,
  descriptionId: PropTypes.string,
  isCore6334Enabled: PropTypes.bool,
};

YearDropdownUI.defaultProps = {
  newModelYears: null,
  defaultYear: null,
  value: '',
  descriptionId: null,
  isCore6334Enabled: false,
};

export const YearDropdown = connectToModel(YearDropdownUI, {
  newModelYears: bindToPath(({ params }) => buildLatestVehiclesPath(params), VehicleModel),
  defaultYear: bindToPath(({ params }) => buildMakeModelDefaultYear(params), VehicleModel),
});

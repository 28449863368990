import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { get, minBy, sortBy, flow } from 'lodash';
import { connect } from 'react-redux';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { VisitorEntities } from 'client/data/models/visitor';
import {
  buildTrimSummariesPath,
  InventoryCoreConfigurationsModel,
  InventoryTrimSummaryEntities,
} from 'client/data/models/inventory-core-configurations';
import {
  buildTrimSummariesCoreConfigurationVehicles,
  calculateEstimatedSavingsConfiguration,
} from 'site-modules/shared/utils/core-page/core-configurations';
import { buildRecommendedTrimPath, EditorialReviewModel } from 'client/data/models/editorial-review';
import {
  CORE_PRICING_CAROUSEL_EVENTS,
  CorePricingCarouselEngagementHandler,
} from 'client/engagement-handlers/core-pricing-carousel-engagement-handler/core-pricing-carousel-engagement-handler';
import { EventToolbox } from 'client/utils/event-toolbox';
import { EngagementHandler } from 'client/engagement-handlers';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { CorePageParams, getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getSubmodelIdentifier } from 'site-modules/shared/utils/core-page/page-helpers';
import { getBuildAndPriceLink } from 'site-modules/shared/utils/build-and-price-link-constructor';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { getLeadFormCreativeId } from 'site-modules/shared/components/lead-form/utils/lead-form-creative-id';
import { getEspLabel } from 'site-modules/shared/utils/core-page/suggested-price';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { SCROLL_DURATION, SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';
import { Link } from 'site-modules/shared/components/link/link';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { LoadingSpinner } from 'site-modules/shared/components/loading-spinner/loading-spinner';
import { TransparentPricingLeadFormDrawer } from 'site-modules/shared/components/lead-form/transparent-pricing-lead-form/transparent-pricing-lead-form-drawer';
import { PricingHeader } from 'site-modules/shared/components/core-page/pricing-header/pricing-header';
import { CARD_HEIGHT, MAX_TRIMS_COUNT, TrimBarsList } from './trim-bars-list';

import './trim-pricing-bars.scss';

EngagementHandler.register(CorePricingCarouselEngagementHandler);

const TOTAL_CARD_HEIGHT = CARD_HEIGHT + 16; // card height + margin
const LAST_CARD_HEIGHT = 30;

export function TrimPricingBarsUI({
  params,
  vehicle,
  visitorLocation,
  className,
  barClassName,
  trimSummaries,
  recommendedTrim,
  pageNameLegacy,
  leadFormName,
  leadFormOptions,
  creativeId,
  isMobile,
  pricingAdPos,
}) {
  const [leadFormData, setLeadFormData] = useState(null);
  const [isLeadFormOpen, toggleLeadFormOpen] = useToggle(false);
  const [currentTrimSummaries, setCurrentTrimSummaries] = useState(trimSummaries);
  const isInitialLoad = useRef(true);
  const containerRef = useRef();

  useEffect(() => {
    if (!containerRef.current || !trimSummaries) {
      return;
    }

    EventToolbox.fireCustomEvent(
      isInitialLoad.current ? CORE_PRICING_CAROUSEL_EVENTS.INITIAL_LOAD : CORE_PRICING_CAROUSEL_EVENTS.DATA_UPDATE,
      {
        coreConfigurations: trimSummaries,
        previousCoreConfigurations: currentTrimSummaries,
        leadFormDealerCount: !!get(trimSummaries, '[0].hasLeadFormDealers'),
        previousLeadFormDealerCount: !!get(currentTrimSummaries, '[0].hasLeadFormDealers'),
      },
      containerRef.current
    );

    isInitialLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimSummaries]);

  useEffect(() => {
    if (trimSummaries) {
      setCurrentTrimSummaries(trimSummaries);
    }
  }, [trimSummaries]);

  const displayLeadForm = useCallback(
    ({
      currentTarget: {
        dataset: { index },
      },
    }) => {
      const coreConfiguration = currentTrimSummaries[+index];
      const { id, trim, suggestedPrice, totalMsrp, baseMsrp } = coreConfiguration;

      setLeadFormData({
        params: {
          styleId: id,
          trim,
        },
        options: {
          coreConfiguration,
          bestDealPrice: suggestedPrice || totalMsrp || baseMsrp,
        },
      });
      toggleLeadFormOpen();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTrimSummaries]
  );

  if (!currentTrimSummaries) {
    return null;
  }

  const currentCreativeId = creativeId || `new-pricing_module-${pageNameLegacy}`;
  const estimatedSavingsTrim = calculateEstimatedSavingsConfiguration(currentTrimSummaries);
  const hasDealers = currentTrimSummaries.some(({ hasLeadFormDealers }) => hasLeadFormDealers);

  const cheapestTrim = minBy(currentTrimSummaries, trim => get(trim, 'pricing.totalMsrp', 0));
  const cheapestMsrp = get(cheapestTrim, 'totalMsrp', 0);
  const cheapestEsp = get(cheapestTrim, 'suggestedPrice', 0);
  const cheapestShowLeadForm = !!get(cheapestTrim, 'hasLeadFormDealers');
  const espLabel = getEspLabel({ totalMsrp: cheapestMsrp, suggestedPrice: cheapestEsp });

  const submodelIdentifier = getSubmodelIdentifier(vehicle.submodels.name, vehicle.model.name);

  let sharedCtaProps = {
    onClick: displayLeadForm,
    'data-index': 0,
    children: 'Compare dealer prices',
    'data-tracking-id': 'pricing_bar_shared_pricing_cta',
  };
  if (!cheapestShowLeadForm) {
    sharedCtaProps = {
      tag: Link,
      children: 'What should I pay',
      'data-tracking-id': get(cheapestTrim, 'inventoryCount')
        ? 'pricing_bar_shared_srp_cta'
        : 'pricing_bar_shared_configure_cta',
      to: get(cheapestTrim, 'inventoryCount')
        ? srpLinkBuilder({
            make: params.make,
            model: params.model,
            submodel: params.submodel,
            year: params.year,
            inventorytype: INVENTORY_TYPES_LOWERCASE.NEW,
            submodelid: params.submodel && submodelIdentifier,
            trim: makeNiceName(get(cheapestTrim, 'trim', '')),
          })
        : getBuildAndPriceLink(params),
    };
  }

  const leadFormCreativeId = getLeadFormCreativeId({
    isNewVin: true,
    pageNameLegacy,
    formName: leadFormName,
  });

  const trimsList = (
    <TrimBarsList
      params={params}
      submodelIdentifier={submodelIdentifier}
      trimSummaries={currentTrimSummaries}
      recommendedTrim={recommendedTrim}
      displayLeadForm={displayLeadForm}
      barClassName={barClassName}
      isMobile={isMobile}
      pricingAdPos={pricingAdPos}
    />
  );

  return (
    <div
      className={classnames('trim-pricing-bars', className)}
      data-tracking-parent={currentCreativeId}
      ref={containerRef}
    >
      {!isMobile && <ScrollElement id="scrollTop-pricing" />}
      <PricingHeader
        className="mb-1_5"
        params={params}
        visitorLocation={visitorLocation}
        vehicle={vehicle}
        hasDealers={hasDealers}
        noPricingData={!cheapestEsp}
        estimatedSavingsTrim={estimatedSavingsTrim}
        creativeId={currentCreativeId}
      />
      <h3 className="heading-4 font-weight-medium text-center mb-1_5">
        {cheapestEsp ? espLabel : `${espLabel} by trim`}
      </h3>
      <LoadingSpinner classes="mb-2" isLoading={!trimSummaries}>
        {currentTrimSummaries.length > MAX_TRIMS_COUNT ? (
          <ContentCollapse
            minHeight={`${MAX_TRIMS_COUNT * TOTAL_CARD_HEIGHT + LAST_CARD_HEIGHT}px`}
            collapseClassName="overflow-hidden-focusable pos-r"
            btnClasses="px-0_5 py-0 medium text-primary-darker"
            btnContainerClasses="py-0 mt-0_25"
            showBtnBelow
            btnTextClasses="font-weight-normal"
            textOpen="See fewer trims"
            textClosed="See all trims"
            btnTrackingId="pricing_module"
            btnIgnoreTrackingValue={false}
            resetParams={{
              offset: SCROLL_OFFSET,
              duration: SCROLL_DURATION,
              to: 'scrollTop-pricing',
            }}
          >
            {trimsList}
          </ContentCollapse>
        ) : (
          trimsList
        )}
      </LoadingSpinner>
      <div className="text-center">
        <Button
          color="blue-50"
          size="lg"
          className="shared-cta size-16 px-1 py-0_5 text-transform-none font-weight-normal"
          data-tracking-id="pricing_bar_shared_cta"
          {...sharedCtaProps}
        />
      </div>
      {hasDealers && (
        <TransparentPricingLeadFormDrawer
          isOpen={isLeadFormOpen}
          onDrawerToggle={toggleLeadFormOpen}
          params={{
            ...get(leadFormData, 'params'),
            vehicle,
            zipCode: get(visitorLocation, 'zipCode'),
            city: get(visitorLocation, 'city'),
          }}
          options={{
            isMobile,
            ...leadFormOptions,
            ...get(leadFormData, 'options'),
            creativeId: leadFormCreativeId,
          }}
          creativeId={leadFormCreativeId}
        />
      )}
    </div>
  );
}

TrimPricingBarsUI.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  visitorLocation: VisitorEntities.Location,
  className: PropTypes.string,
  barClassName: PropTypes.string,
  trimSummaries: InventoryTrimSummaryEntities.TrimSummaries,
  recommendedTrim: PropTypes.string,
  pageNameLegacy: PropTypes.string,
  leadFormName: PropTypes.string,
  leadFormOptions: PropTypes.shape({}),
  creativeId: PropTypes.string,
  isMobile: PropTypes.bool,
  pricingAdPos: PropTypes.string,
};

TrimPricingBarsUI.defaultProps = {
  visitorLocation: null,
  className: null,
  barClassName: undefined,
  trimSummaries: null,
  recommendedTrim: null,
  pageNameLegacy: 'null',
  leadFormName: LEAD_FORMS_CREATIVE_ID.PRICING_CARDS_FORM,
  leadFormOptions: null,
  creativeId: null,
  isMobile: false,
  pricingAdPos: null,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  visitorLocation: get(state, 'visitor.location', {}),
  pageNameLegacy: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
});

export const TrimPricingBars = connect(mapStateToProps)(
  connectToModel(TrimPricingBarsUI, {
    trimSummaries: bindToPath(
      ({ vehicle }) => buildTrimSummariesPath(getParamsFromVehicle(vehicle)),
      InventoryCoreConfigurationsModel,
      flow(
        buildTrimSummariesCoreConfigurationVehicles,
        trims => sortBy(trims, ({ totalMsrp, suggestedPrice, baseMsrp }) => suggestedPrice || totalMsrp || baseMsrp)
      )
    ),
    recommendedTrim: bindToPath(({ params }) => buildRecommendedTrimPath(params), EditorialReviewModel),
  })
);
